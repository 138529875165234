// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap');

.react {
    html,
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    span,
    label {
        color: $text-default !important;
    }

    p span,
    p {
        font-family: Oswald;
        line-height: 1.6;
        color: $text-default-light;
    }

    h4 {
        font-family: PasseroOne;
        font-size: 1.625rem;
    }

    h2 {
        color: #fff5af !important;
        text-align: center;
    }

    h3 {
        font-family: PasseroOne;
        font-size: 2.25rem;
    }

    h1 {
        font-family: PasseroOne;
    }

    /* Select Style */
    select {
        background-image: url('../assets/images/buttonBk.png');
        background-size: 100% 100%;
        background-color: transparent !important;
        border: none !important;
        height: 30px !important;
        padding: 3px 15px !important;
        color: #120c05 !important;
        font-size: 16px !important;
        outline: none !important;
    }

    select:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    /* Button Style */
    button {
        background-image: url('../assets/images/buttonBk.png');
        background-size: 100% 100%;
        background-color: transparent !important;
        border: none !important;
        height: 30px !important;
        padding: 3px 15px !important;
        color: #120c05 !important;
        font-size: 16px !important;
        outline: none !important;
    }
    button.bg-none {
        background-image: none;
    }

    button:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    button.btn-md {
        min-width: 120px;
    }
    button.btn-sm {
        min-width: 60px;
    }

    .btn.disabled,
    .btn:disabled {
        opacity: 0.4;
    }

    button.dropdown-toggle {
        background-image: none !important;
        color: $text-default !important;
    }

    button.dropdown-toggle:hover {
        box-shadow: none !important;
    }

    button.dropdown-toggle::after {
        margin-left: 0.5em !important;
        margin-top: 0.5em !important;
        vertical-align: 0.1em;
    }

    button:hover {
        transition: 0.3s;
        -webkit-filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.4));
        filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.4));
    }

    .c-add-btn {
        max-width: 45px !important;
        width: 35px !important;
        min-width: 35px !important;
        height: 35px !important;
        top: 7px;
        text-align: center;
        background-image: url('../assets/images/plusBg.png') !important;
    }

    p {
        font-size: 1rem;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(253, 243, 173, 0.3) !important;
        opacity: 1; /* Firefox */
    }

    .f-oswald {
        font-family: Oswald !important;
    }

    .text-primary-dark {
        color: $text-default-dark;
        opacity: 0.3;
    }

    .text-primary-light {
        color: $text-default-light !important;
    }

    .text-yellow {
        color: #ffd800 !important;
        font-size: 28px !important;
    }

    .text-coupon {
        opacity: 0.5;
        text-decoration: line-through;
    }

    a:hover {
        transition: 0.3s;
        color: $text-default-light !important;
        text-decoration: none !important;
    }

    .form-group label {
        font-size: 20px;
    }

    .social-icons {
        a {
            text-decoration: none;
            font-size: 18px;
        }
    }

    .error-text {
        font-family: PasseroOne;
        font-size: 14;
        color: red !important;
    }
    .green-text {
        color: rgb(9, 213, 2) !important;
    }

    .input-wrapper {
        input {
            background-image: url('../assets/images/searchInputBg.png');
            height: 35px;
        }
    }

    input {
        background-color: transparent !important;
        background-image: url('../assets/images/inputBg.png');
        background-size: 100% 100%;
        height: 40px;
        border-radius: 0 !important;
        border: none !important;
        font-size: 20px !important;
        color: $text-default-light !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .w-90 {
        width: 90%;
    }

    .w-80 {
        width: 80%;
    }

    .w-70 {
        width: 70%;
    }

    .w-60 {
        width: 60%;
    }

    .w-50 {
        width: 50%;
    }

    .w-40 {
        width: 40%;
    }

    .w-30 {
        width: 30%;
    }

    .w-20 {
        width: 20% !important;
    }

    .w-15 {
        width: 15%;
    }

    .w-10 {
        width: 10%;
    }

    .w-5 {
        width: 5%;
    }

    hr {
        border-color: #f5f5f2;
    }

    .modal {
        border: none !important;
        box-shadow: none;
        // display: flex !important;
        align-items: center;
    }
    .modal-dialog {
        // max-width: 350px;
        // max-width: 600px !important;
    }
    .modal-content {
        background-color: transparent !important;
        border: none;

        .medallion-modal {
            border: none;
            background-image: url('../assets/images/modalBg.png');
            background-size: 100% 100%;
        }

        .signup-modal {
            border: none;
            // width: 350px;
            background-image: url('../assets/images/signUpBg.png');
            background-size: 100% 100%;
        }
    }

    .gradient-bg {
        background-image: url('../assets/images/gradientBg.png');
        background-size: 100% 100%;
    }

    .left-edge {
        left: -50%;
        top: 0;
        width: 150%;
        height: 35px;
        opacity: 0.3;
    }

    .modal-content {
        // background-color: #2c2a26 !important;
    }

    .modal-backdrop.show {
        opacity: 0 !important;
    }

    .modal-body {
        color: $text-default;
    }

    .c-menu-info {
        background-image: url('../assets/images/menuBg.png');
        background-size: 100% 100%;
        height: 45px;
        width: fit-content;
        font-size: 22px;
        padding-left: 20px;
        .item {
            padding: 0 30px;
            color: $text-default-light;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            img {
                min-width: 20px;
                margin-right: 10px;
            }
        }

        .item:not(:last-child) {
            border-right: 2px solid rgba(0, 0, 0, 0.7);
        }
    }

    .c-default-layout {
        background-color: #0d0908;
        .c-wrapper {
            position: relative;
        }
        .top-bg {
            background-image: url('../assets/images/topBg.png');
            background-size: 100% 100%;
            width: 100%;
            position: absolute;
            top: -4;
            left: 0;
            height: 200px;
        }
        .bottom-bg {
            width: 100%;
            height: 400px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-size: 100% 100%;
            background-image: url('../assets/images/bottomBg.png');
        }
    }

    .c-default-layout footer {
        background-color: transparent;
        border-top: none;
        height: auto;
    }

    .c-default-layout footer,
    .c-body {
        position: relative;
        /* kiet: if body & footer have same z-index then body's child always below footer */
        // z-index: 1;
    }

    .c-default-layout header {
        background: transparent;
        border-bottom: none;
        position: relative;
        z-index: 99;

        img.logo {
            // width: 100%;
            margin-top: 6px;
            height: 73px;
            max-width: 130px;
        }

        a,
        span {
            font-size: 15px;
        }

        .c-menus li a {
            margin-right: 12px;
        }

        .c-menus li:not(:last-child):after {
            // content: ">>";
            color: $text-default-light;
            position: absolute;
            right: 0px;
            top: 1px;
        }
    }

    .c-filter-links {
        a {
            padding: 0 10px;
            font-size: 18px;
            opacity: 0.4;
            // line-height: 15px;
        }

        a.active {
            opacity: 1;
        }

        a:not(:last-child) {
            border-right: 0.1em solid #fff5af;
        }
    }

    .table {
        // border-bottom: 1px solid #d8dbe0;
        th,
        td,
        div,
        p {
            color: $text-default;
        }
        th {
            font-weight: 400;
        }
        th:not(:first-child) {
            font-weight: 400;
            text-align: center;
        }
        td {
            text-align: center;
            font-size: 18px;
            vertical-align: middle;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        th {
            border-top: none;
        }
    }

    ul.pagination {
        justify-content: flex-end !important;
        a {
            background-color: transparent !important;
            border: none;
            font-size: 18px !important;
            margin: 0 10px;
        }
        li.active {
            a {
                background-color: #3d3629 !important;
            }
        }
    }

    .Toastify__toast {
        background-color: transparent;
        color: #120c05 !important;
        font-weight: bold !important;
        background-image: url(../assets/images/btn-right.png);
    }
    .Toastify__toast-icon {
        background: #fff;
        border-radius: 50%;
        margin-left: 20px;
    }
    .Toastify__progress-bar {
        height: 10px;
        left: 25px !important;
    }

    // Fake Input Background
    .input-bg {
        background-image: url('../assets/images/inputBg.png');
        background-size: 100% 100%;
    }

    .text-default-color {
        color: $text-default;
    }

    .item-bg-color {
        background-color: $item-bg;
    }

    .selected-outline {
        outline-style: solid;
        outline-offset: -1px;
        outline-width: 3px;
        outline-color: $text-default-light;
    }
    // pagination button custom
    .page-item.disabled .page-link {
        background-color: transparent;
        border: none;
    }
    a:focus {
        outline: none !important;
    }
}
